import { Auth0Client } from "@auth0/auth0-spa-js";
import { isAuthenticated, user } from "$lib/services/store";

export const auth0 = new Auth0Client({
  domain: `${import.meta.env.VITE_AUTH0_DOMAIN}`,
  // clientId is issued by Auth0 for this application
  clientId: `${import.meta.env.VITE_AUTH0_CLIENT_ID}`,
  authorizationParams: {
    // needs to be changed along with the same property in Auth0 dashboard
    redirect_uri: `${import.meta.env.VITE_AUTH0_REDIRECT_URI}/profile`,
    audience: `${import.meta.env.VITE_AUTH0_AUDIENCE}`,
    ui_locales: "sv",
  },
});

export const loginAuth0 = async (redirectTo: string = '/profile'): Promise<void> => {
  await auth0.loginWithRedirect({
    authorizationParams: { redirect_uri: `${import.meta.env.VITE_AUTH0_REDIRECT_URI}${redirectTo}` }
  });
};

export const logoutAuth0 = async (): Promise<void> => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  isAuthenticated.set(false);
  user.set(null);

  auth0.logout();
};
